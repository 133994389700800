import React from 'react'

function GoldGlobalMarketMobile() {
  return (
    <div>
      GoldGlobalMarketMobile
    </div>
  )
}

export default GoldGlobalMarketMobile
