import React from 'react'

function CurrentProjectMobile() {
  return (
    <div>
      CurrentProjectMobile
    </div>
  )
}

export default CurrentProjectMobile
