import React, { useEffect, useState } from 'react'
import "./NavBarDesktop.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MaskGroupIcon from "../../../../assets/images/Mask-group.svg";
import LogoIcon from "../../../../assets/images/logo-gds.svg";


function NavBarDesktop() {
  const [selectedTab, setSelectedTab] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedTab(pathname?.split("/")[1])
  }, [pathname]);

  return (
    <div>
      <div class="header-container">
        <div class="first-container">
          <div class="design-div">
            <img src={MaskGroupIcon} />
          </div>
          <div class="header-logo" onClick={() => navigate("/")}>
            <img src={LogoIcon} />
          </div>
        </div>
        <div class="navigation-container">
          <Link to="/about-us" class={selectedTab === "about-us" ? `navigator selected-navigator` : `navigator`}>About us</Link>
          <Link to="/current-project" class={selectedTab === "current-project" ? `navigator selected-navigator` : `navigator`}>Current Project</Link>
          <Link to="/contact" class={selectedTab === "contact" ? `navigator selected-navigator` : `navigator`}>Contact</Link>
          <Link to="/gallery" class={selectedTab === "gallery" ? `navigator selected-navigator` : `navigator`}>Gallery</Link>
          {/* <Link to="/more-ggm" class={selectedTab?.split("-")[0] === "more" ? `navigator selected-navigator` : `navigator`}>More</Link> */}
          
          <Link to="/more-ggm" class={selectedTab === "more-ggm" ? `navigator selected-navigator` : `navigator`}>Gold Global Market</Link>
          <Link to="/more-gme" class={selectedTab === "more-gme" ? `navigator selected-navigator` : `navigator`}>Mining Exploration</Link>
        </div>
      </div>
    </div>
  )
}

export default NavBarDesktop
