import React from 'react'

function GoldMiningExplorationMobile() {
  return (
    <div>
      GoldMiningExplorationMobile
    </div>
  )
}

export default GoldMiningExplorationMobile
