import React from 'react'

function GalleryMobile() {
  return (
    <div>
      GalleryMobile
    </div>
  )
}

export default GalleryMobile
