import React from 'react'

function FooterMobile() {
  return (
    <div>
      FooterMobile
    </div>
  )
}

export default FooterMobile
