// context/MyContext.js
import React, { createContext, useState } from 'react';

// Create the context
export const MyContext = createContext();

// Define a provider component
export const MyProvider = ({ children }) => {
const [isMobile, setIsMobile] = useState(false);
  return (
    <MyContext.Provider value={{ isMobile, setIsMobile }}>
      {children}
    </MyContext.Provider>
  );
};
