import React from 'react'
import "./GalleryDesktop.css"
import GPhoto1 from "../../../../assets/images/g-photo-1.svg"
import GPhoto2 from "../../../../assets/images/g-photo-2.svg"
import GPhoto3 from "../../../../assets/images/g-photo-3.svg"
import GPhoto4 from "../../../../assets/images/g-photo-4.svg"
import GPhoto5 from "../../../../assets/images/g-photo-5.svg"
import GPhoto6 from "../../../../assets/images/g-photo-6.svg"
import GPhoto7 from "../../../../assets/images/g-photo-7.svg"
import GPhoto8 from "../../../../assets/images/g-photo-8.svg"
import MultiImage from "../../../../assets/images/multi-img.png"
import SomImage from "../../../../assets/images/som-img.png"
import GImage1 from "../../../../assets/images/GImage1.svg"
import GImage2 from "../../../../assets/images/GImage2.svg"
import GImage3 from "../../../../assets/images/GImage3.svg"
import GImage4 from "../../../../assets/images/GImage4.svg"
import GImage5 from "../../../../assets/images/GImage5.svg"
import GImage6 from "../../../../assets/images/GImage6.svg"
import GImage7 from "../../../../assets/images/GImage7.svg"
import GImage8 from "../../../../assets/images/GImage8.svg"
import GImage9 from "../../../../assets/images/GImage9.svg"
import GImage10 from "../../../../assets/images/GImage10.svg"
import GImage11 from "../../../../assets/images/GImage11.svg"

function GalleryDesktop() {
  return (
    <div className='gallery-main-container'>
      <div className='gallery-first-container'>
        <div className='km-container'>
          <div className='km-text-head'>KEY MOMENTS</div>
          <div>Explore pivotal moments in gold mining history and market evolution. From groundbreaking discoveries to major industry advancements, these key events have shaped the global gold market and its future.</div>
        </div>
        <div className='marquee-container'>

          <div class="marquee">
            <div className='mq-sc'>
              <><img src={GPhoto1} /></>
              <><img src={GPhoto2} /></>
              <><img src={GPhoto3} /></>
              <><img src={GPhoto4} /></>
              <><img src={GPhoto5} /></>
              <><img src={GPhoto6} /></>
              <><img src={GPhoto7} /></>
            </div>
          </div>
        </div>
      </div>
      <div className='multi-img-contt'>
        <img src={MultiImage} className='multi-image' />
      </div>

      <div className='second-marquee-contt'>
        <div class="marquee">
          <div className='mq-scc'>
            <><img src={GImage1} /></>
            <><img src={GImage2} /></>
            <><img src={GImage3} /></>
            <><img src={GImage4} /></>
            <><img src={GImage5} /></>
            <><img src={GImage6} /></>
            <><img src={GImage7} /></>
            <><img src={GImage8} /></>
            <><img src={GImage9} /></>
            <><img src={GImage10} /></>
            <><img src={GImage11} /></>
          </div> </div>
      </div>

      <div className='som-container'>
        <div className='som-text'>SOME OTHER MOMENTS</div>
        <div className='som-img-contt'><img src={SomImage} className='multi-image' /></div>
      </div>
    </div>
  )
}

export default GalleryDesktop
