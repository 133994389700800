import { React, useEffect } from 'react'
import Goldimg from "../../../../../assets/images/goldball.svg"
import Yellow from "../../../../../assets/images/yellowimg.svg"
import Geo from "../../../../../assets/images/geopolitical.svg"
import Infl from "../../../../../assets/images/infl.svg"
import InvestmentImg from "../../../../../assets/images/investment.svg"
import Mapimg from "../../.../../../../../assets/images/map-img.svg"
import StarImg from "../../.../../../../../assets/images/starimg.svg"
import Dimg from "../../.../../../../../assets/images/dimg.svg"
import Countryimg from "../../.../../../../../assets/images/country.svg"
import CurrencyImg from "../../.../../../../../assets/images/currency.svg"
import "./GoldGlobalMarketDesktop.css";
function GoldGlobalMarketDesktop() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: [
        ["TVC:GOLD|120M"]
      ],
      chartOnly: false,
      width: "100%",
      height: "100%",
      locale: "en",
      colorTheme: "light",
      autosize: false,
      showVolume: true,
      showMA: false,
      hideDateRanges: false,
      hideMarketStatus: false,
      hideSymbolLogo: false,
      scalePosition: "right",
      scaleMode: "Normal",
      fontFamily: "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
      fontSize: "10",
      noTimeScale: false,
      valuesTracking: "2",
      changeMode: "price-and-percent",
      chartType: "area",
      lineWidth: 2,
      lineType: 0,
      dateRanges: ["120m|1M"],
      downColor: "#f7525f",
      upColor: "#22ab94"
    });
    document.getElementById('tradingview-widget').appendChild(script);
  }, []);
  return (
    <div className='h-100  d-flex flex-column align-items-center ' style={{ marginLeft: "4rem", marginRight: "4rem" }}>
      <div className='gold-market h-100 position-relative d-flex justify-content-center align-items-center'>
        <div className='d-flex flex-column text-center'>
          <p className='golbal-text'>  Global Gold Market at a Glance </p>
          <p className='explore-gold'> Explore Gold Prices, Top Producers, and Global Reserves</p>
          <img src={Goldimg} alt=" " className=' gold-ball' />
        </div>
      </div>

      <div className='blue-div border-radius-3'>
        <div className='blue-first-container'>
          <p className='blue-heading'>Understanding the Global Gold Market</p>
          <p className='blue-small-text'>Gold, with the chemical symbol Au (from the Latin word Aurum) and an atomic number of 79, is a slightly orange-yellow, dense, soft, malleable, and ductile element. As a transition metal, gold is one of the least reactive chemical elements, which is why it is often found in its native state on Earth, as nuggets or grains in rocks, veins, and alluvial deposits. It occurs in solid solution with native silver and is naturally alloyed with other metals like copper and palladium, as well as mineral inclusions such as pyrite.</p>
        </div>
        <div>
          <p className='blue-second-heading'>Introduce the major participants in the gold market and explain their roles</p>

          <div className='d-flex justify-content-around '>
            <div className='d-flex flex-column align-items-center text-center'>
              <div style={{ width: "81px ", height: "81px" }} className='yellow-img'>
                <img src={Yellow} alt="" />

              </div>
              <p className='mining-company-text'>Mining Company</p>
              <p className='yellow-subtext'>
                Mining companies extract gold and play <br />a central role in determining supply.
              </p>
            </div>
            <div className='d-flex flex-column text-center align-items-center'>
              <div style={{ width: "81px ", height: "81px" }} className='yellow-img'>
                <img src={Yellow} alt="" />

              </div>
              <p className='mining-company-text'>Mining Company</p>
              <p className='yellow-subtext'>
                Mining companies extract gold and play <br />a central role in determining supply.
              </p>
            </div>
            <div className='d-flex flex-column text-center align-items-center'>
              <div style={{ width: "81px ", height: "81px" }} className='yellow-img'>
                <img src={Yellow} alt="" />

              </div>
              <p className='mining-company-text'>Mining Company</p>
              <p className='yellow-subtext'>
                Mining companies extract gold and play <br />a central role in determining supply.
              </p>
            </div>
          </div>

        </div>
      </div>

      <div className='d-flex flex-column align-items-center bg-cream marginT-4 border-radius-3'>
        <p className='geo-text'>Geopolitical Factors</p>
        <p className='geo-subtext'>The 2008 recession and the Lehmann Brothers chaos led to global markets bleeding <br /> red. The volatility of the global markets for the next few years resulted in the gold <br /> prices jumping from $700 per ounce in 2008 to $1,900 per ounce in 2011.</p>
        <img src={Geo} alt="" />
      </div>
      {/* <div className='d-flex flex-column align-items-center  bg-gray border-radius-3'>
        <p className='geo-text'>Inflation vs. Gold Price</p>
        <p className='geo-subtext'>Gold is known to be inflation hedge and this reputation is well-earned. Since inflation means the decrease in the value <br/> of fiat (paper, unbacked by metals) money, people turn to assets that proved to be money throughout history - gold & silver.<br/> Platinum used to be money as well, but it's not as recognizable as silver and gold, so the reputation of the ultimate inflation-<br/>hedge goes to the former metals, gold in particular (silver being somewhat industrial metal nowadays).</p>
        <img src={Infl} alt="" />
      </div> */}
      <div className='d-flex flex-column align-items-center  bg-gray border-radius-3'>
        <p className='geo-text'>Inflation vs. Gold Price</p>
        <p className='geo-subtext'>Gold is known to be inflation hedge and this reputation is well-earned. Since inflation means the decrease in the value <br /> of fiat (paper, unbacked by metals) money, people turn to assets that proved to be money throughout history - gold & silver.<br /> Platinum used to be money as well, but it's not as recognizable as silver and gold, so the reputation of the ultimate inflation-<br />hedge goes to the former metals, gold in particular (silver being somewhat industrial metal nowadays).</p>
        <img src={Infl} alt="" />
      </div>
      <div className='marginT-4 w-100'>
        <div>
          <p className='text-center'>ECONOMIC CONDITIONS</p>
        </div>
        <hr />
        <div className='d-flex flex-column align-items-center bg-white'>
          <div className='d-flex gap-15'>
            <p className='interest-text intrest-color'>Interest Rates</p>
            <p className='vs'>VS</p>
            <p className='interest-text text-white price-color'>Gold Price</p>
          </div>
          <p className='geo-subtext'>The 2008 recession and the Lehmann Brothers chaos led to global markets bleeding <br /> red. The volatility of the global markets for the next few years resulted in the gold <br /> prices jumping from $700 per ounce in 2008 to $1,900 per ounce in 2011.</p>
          <p className='geo-text'>Geopolitical Factors</p>
          <img src={Geo} alt="" />
        </div>
      </div>

      <div className='marginT-4 w-100 d-flex align-items-center flex-column'>
        <hr className='w-100' style={{ marginBottom: "2rem" }} />
        <p className='currency-text'> Currency Strength</p>
        <img src={CurrencyImg} alt="" />
      </div>
      <hr className='w-100' style={{ marginTop: "2rem" }} />

      <div className='d-flex justify-content-center marginT-4'>
        <img src={InvestmentImg} style={{ width: "100%", margin: "25px" }} alt="" />
      </div>
      <div className='d-flex pie-div border-radius-3 '>
        <div className=' pie-container'>
          <p className='pie-main'>GOLD GLOBAL MARKET: <br /> UTILITY OF GOLD</p>
          <p className='pie-heading'>
            This pie chart highlights the main uses of gold in 2022
          </p>
          <ul>
            <li className='pie-subheading'>Jewellery manufacturing</li>
            <li className='pie-subheading'>Bullion (gold bars and ingots)</li>
            <li className='pie-subheading'>Gold coins and medals</li>
            <li className='pie-subheading'>Electronics industry</li>
            <li className='pie-subheading'>Other uses</li>
          </ul>
          <p className='pie-subheading'>This data is from the USGS Mineral Commodity Summaries, January 2023.</p>
        </div>
      </div>
      <div className='cfd-container d-flex justify-content-center align-items-center'>
        <div id="tradingview-widget" style={{ width: '100%', height: '100%' }} />;
      </div>

      <div className='top-gold d-flex justify-content-center marginT-4'>
        <p className='top-gold-text'>Top Gold Producing Countries Worldwide</p>
      </div>

      <div>
        <img src={Mapimg} alt="" />
      </div>
      <div className='future-container w-100 marginT-4 border-radius-3' style={{ paddingTop: "2rem" }}>
        < img src={Dimg} alt="" className='dimg' />
        <p className='future-text'>Future Outlook: Emerging Gold Producers</p>
        <p className='future-subtext'>Exploring the Regions Poised to Shape the Global Gold Market</p>

        <div>

          <div className='d-flex align-items-start star-div'>
            <img src={StarImg} alt="" />
            <div className=''>
              <p className='demand-text '>Investment Demand</p>
              <p className='demand-subtext'>Gold will remain a safe-haven asset during economic uncertainty, driven by inflation and market volatility.</p>
            </div>
          </div>


          <div className='d-flex align-items-start star-div'>
            <img src={StarImg} alt="" />
            <div className=''>
              <p className='demand-text '>Jewellery Demand</p>
              <p className='demand-subtext'>Increased disposable incomes in emerging markets, particularly in India and China, will boost gold jewelry consumption.</p>
            </div>
          </div>



          <div className='d-flex align-items-start star-div'>
            <img src={StarImg} alt="" />
            <div className=''>
              <p className='demand-text '>Industrial Applications</p>
              <p className='demand-subtext'>Continued purchases by central banks to hedge against instability will affect overall demand.</p>
            </div>
          </div>



          <div className='d-flex align-items-start star-div'>
            <img src={StarImg} alt="" />
            <div className=''>
              <p className='demand-text '>Central Bank Reserves</p>
              <p className='demand-subtext'>Gold will remain a safe-haven asset during economic uncertainty, driven by inflation and market volatility.</p>
            </div>
          </div>



          <div className='d-flex align-items-start star-div'>
            <img src={StarImg} alt="" />
            <div className=''>
              <p className='demand-text '>Sustainable Practices</p>
              <p className='demand-subtext'>There will be a rising preference for ethically sourced gold due to consumer awareness.</p>
            </div>
          </div>



          <div className='d-flex align-items-start star-div'>
            <img src={StarImg} alt="" />
            <div className=''>
              <p className='demand-text '>Emerging Markets</p>
              <p className='demand-subtext'>Expanding middle classes in Asia and Africa will drive higher gold consumption.</p>
            </div>
          </div>




          <div className='d-flex align-items-start star-div'>
            <img src={StarImg} alt="" />
            <div className=''>
              <p className='demand-text '>Digital Gold</p>
              <p className='demand-subtext'>Innovations in digital currencies and blockchain may attract new investors.</p>
            </div>
          </div>




          <div className='d-flex align-items-start star-div'>
            <img src={StarImg} alt="" />
            <div className=''>
              <p className='demand-text '>Economic Conditions</p>
              <p className='demand-subtext'>Factors like interest rates and inflation will shape gold's investment appeal.</p>
            </div>
          </div>
        </div>

      </div>
      <div className='marginT-4 country-container'>
        <p className='world-text'>Gold Reserves Worldwide</p>
        <p className='world-subtext'>10 Countries with the largest gold reserves in the world</p>
        <div>
          <img src={Countryimg} alt="" />
        </div>
      </div>


    </div>
  )
}

export default GoldGlobalMarketDesktop
