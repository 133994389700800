import React from 'react'

function ContactMobile() {
  return (
    <div>
      ContactMobile
    </div>
  )
}

export default ContactMobile
