import React from 'react';
import "./HomeDesktop.css";
import ImageOne from "../../../../assets/images/image-one.svg"; // correct path for first image
import ImageTwo from "../../../../assets/images/image-two.svg";
import ImageThree from "../../../../assets/images/image-three.svg";
import HeroImageOne from "../../../../assets/images/hero-image-one.svg";
import HeroImageTwo from "../../../../assets/images/hero-image-two.svg";
import HeroImageThree from "../../../../assets/images/hero-image-three.svg";
import MiningDesignOne from "../../../../assets/images/mining-design-one.svg";
import MiningDesignTwo from "../../../../assets/images/mining-design-two.svg";
import MiningDesignThree from "../../../../assets/images/mining-design-three.svg";
import MiningDesignFour from "../../../../assets/images/mining-design-four.svg";

function HomeDesktop() {
  return (
    <div>
      <div className="carousel-background">
        <div className="content">
          <div className="carousel-sub-container">
            <div className="car-sc-one car-one-contt">
              <div>
                <div className="ch-new-title">FOSTERING PROSPERITY, SUSTAINING GROWTH</div>
                <div className="car-heading-title">
                  From <span style={{ color: "#E7CD12" }}>Powerhouse to Pioneer</span>
                </div>
                <div className="car-heading-title">Responsible Mining for a</div>
                <div className="car-heading-title">Sustainable Future</div>
              </div>
              <div className="car-content">
                GDS delivers innovative solutions in gold, silver, and rare earth
                <div>mining with a commitment to sustainability and integrity.</div>
              </div>
              <button className="read-more-btn">Read more -></button>
            </div>

            <div className="car-sc-two">
              <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-interval="3000">
                <ol className="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100" src={ImageOne} alt="First slide" />
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={ImageTwo} alt="Second slide" />
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={ImageThree} alt="Third slide" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="carousel-contt">
          <div id="carouselExampleIndicators2" className="carousel slide" data-ride="carousel" data-interval="3000">
            <ol className="carousel-indicators">
              <li data-target="#carouselExampleIndicators2" data-slide-to="0" className="active"></li>
              <li data-target="#carouselExampleIndicators2" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators2" data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img className="d-block w-100" src={HeroImageOne} alt="First slide" />
              </div>
              <div className="carousel-item">
                <img className="d-block w-100" src={HeroImageTwo} alt="Second slide" />
              </div>
              <div className="carousel-item">
                <img className="d-block w-100" src={HeroImageThree} alt="Third slide" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pillers-head-contt">
        <p className="pillar-heading">Pillars of Success</p>
        <p className="pillar-sub-heading">Encompassing financial power, expertise, partnerships, and diverse industries.</p>
      </div>

      <div className="card-container">
        <div className="card-div">
          <p className="card-heading">Global Presence</p>
          <p>Through 7 strategic global partnerships, we foster collaboration and achieve excellence.</p>
        </div>
        <div className="card-div">
          <p className="card-heading">Professional Workforce</p>
          <p>Over 70 skilled professionals drive our continued success and innovation.</p>
        </div>
        <div className="card-div">
          <p className="card-heading">Net Worth</p>
          <p>With a net worth exceeding $20 million, our financial strength is a testament to our leadership.</p>
        </div>
        <div className="card-div">
          <p className="card-heading">Industry Expertise</p>
          <p>With 18+ years of experience, we bring seasoned insight and expertise to every venture.</p>
        </div>
        <div className="card-div">
          <p className="card-heading">Defence & Mining Solutions</p>
          <p>Specialising in de-mining equipment, advanced tactical gear, and safety solutions for mining and related sectors.</p>
        </div>
        <div className="card-div">
          <p className="card-heading">Real Estate Leadership</p>
          <p>A key player in both commercial and residential real estate, shaping the future of urban development.</p>
        </div>
      </div>

      <div className="milestone-containe">
        <p className="milestone-heading">OUR KEY MILESTONES</p>
        <div className="milestone-card-container">
          <div className="card-width">
            <p className="expertise-heading">Expertise</p>
            <p className="expertise-heading-1">18+</p>
            <p className="expertise-heading-2">Years of expertise</p>
          </div>
          <div className="card-width">
            <p className="expertise-heading">Growth</p>
            <p className="expertise-heading-1">45%</p>
            <p className="expertise-heading-2">In Last 2 Financial Year</p>
          </div>
          <div className="card-width">
            <p className="expertise-heading">Global Presence</p>
            <p className="expertise-heading-1">07</p>
            <p className="expertise-heading-2">Global Partnership</p>
          </div>
          <div className="card-width">
            <p className="expertise-heading">Employees</p>
            <p className="expertise-heading-1">70+</p>
            <p className="expertise-heading-2">Active Work Force</p>
          </div>
        </div>
      </div>

      <div className="mining-container">
        <div className="mining-design-container">
          <div className="new-contt">
            <div className="mining-design-image">
              <img src={MiningDesignOne} className="img-mining" alt="Mining Design One" />
            </div>
            <div className="mining-design-image" style={{ paddingLeft: 20 }}>
              <img src={MiningDesignThree} alt="Mining Design Three" />
              <img src={MiningDesignThree} alt="Mining Design Three" />
              <img src={MiningDesignThree} alt="Mining Design Three" />
            </div>
          </div>
          <div className="new-contt">
            <div className="mining-design-imageee">
              <img src={MiningDesignTwo} className="img-mining" alt="Mining Design Two" />
            </div>
            <div className="mining-design-image">
              <img src={MiningDesignFour} className="img-mining" alt="Mining Design Four" style={{ paddingRight: 40, marginTop: 10 }} />
            </div>
          </div>

          {/* Carousel Section */}
          <div className="mining-carousel">
            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-interval="3000">
              <ol className="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img className="d-block w-100" src={ImageOne} alt="First slide" />
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100" src={ImageTwo} alt="Second slide" />
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100" src={ImageThree} alt="Third slide" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mining-card-content">
          <div className="mining-heading">MINING</div>
          <div className="mining-content">
            We extract precious resources like gold, copper, and silver with care for the planet. Using the latest technology, we ensure responsible mining for a better future.
          </div>
          <button className="read-more-btn">Read more </button>
        </div>
      </div>

      <div className="current-project-container">
        <div className="current-project-sub-container">
          <div className="cpc-heading">Current Projects</div>
          <div className="cpc-content">
            GDS Mining Co., Ltd. proudly operates a gold mine in Attapeu Province, Southern Laos, a region rich in mineral wealth. As we responsibly extract gold, we emphasize sustainability and positive local impact.
          </div>
          <button className="read-more-btn">Read more -></button>
        </div>
      </div>

      <div class="ocv-primary-container">
        <div class="ocv-heading">OUR CORE VALUE</div>
        <div class="ocv-main-container">
          <div class="omc-sub-contt">
            <div class="ocv-cont-1">
              <div class="ocv-card-heading">Integrity and Trust</div>
              <div class="ocv-card-text">We conduct our business with honesty and build trust through
                transparency in all our
                operations.</div>
            </div>
            <div class="ocv-cont-2">
              <div class="ocv-card-heading">Stability and Longevity</div>
              <div class="ocv-card-text">With decades of experience, we ensure long-term stability and a
                steadfast commitment to our
                goals.</div>
            </div>
          </div>
          <div class="omc-sub-contt">
            <div class="ocv-cont-2">
              <div class="ocv-card-heading">Perseverance and Determination</div>
              <div class="ocv-card-text">We approach challenges with persistence and a strong, unwavering
                commitment to success
              </div>
            </div>
            <div class="ocv-cont-1">
              <div class="ocv-card-heading">Strong Partnerships</div>
              <div class="ocv-card-text">We value and nurture long-term partnerships, focusing on mutual
                growth and collaboration.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeDesktop;
