import React from 'react'

function NavBarMobile() {
  return (
    <div>
      NavBarMobile
    </div>
  )
}

export default NavBarMobile
